<template>
  <AppCollapse type="border">
    <AppCollapseItem
      title
      :is-visible="false"
    >
      <template #header>
        <h4 class="card-title font-weight-bolder px-1 py-50">
          {{ $t('filters') }}
        </h4>
      </template>

      <b-card-body class="py-0">
        <b-row>
          <!-- ANCHOR START DATE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('topup.startDate')"
            >
              <flat-pickr
                v-model="startDate"
                class="form-control"
                :placeholder="$t('topup.placeholderSelectDate')"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  maxDate: endDate ? endDate : today,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR END DATE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('topup.endDate')"
            >
              <flat-pickr
                v-model="endDate"
                class="form-control"
                :placeholder="$t('topup.placeholderSelectDate')"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  minDate: startDate,
                  maxDate: today,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- ANCHOR AGENCY CODE -->
            <b-form-group
              label-class="h5 py-0"
              :label="$t('agency.agencyCode')"
            >
              <SelectChildAgency
                id="agency-code"
                :agency-select.sync="agencySelect"
                class="w-100"
                style="font-size: 1rem;"
                :placeholder="$t('saleReport.phAgencyCode')"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR BOOKING CODE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.bookingCode')"
            >
              <b-form-input
                v-model.trim="bookingCode"
                :placeholder="$t('saleReport.phBookingCode')"
                :formatter="trimUpperCaseInput"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <!-- ANCHOR FLIGHT TYPE -->
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.flightType')"
            >
              <v-select
                v-model="flightType"
                class="w-100"
                style="font-size: 1rem;"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="flightTypeOptions"
                label="label"
                :clearable="false"
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                :disabled="Boolean(fixFlightType)"
                @input="val => $emit('update:flightTypeFilter', val)"
              >
                <template #option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR NGÀY BAY -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.flightDate')"
            >
              <flat-pickr
                v-model="flightDate"
                class="form-control"
                :placeholder="$t('topup.placeholderSelectDate')"
                :config="{
                  allowInput: true,
                  dateFormat: 'd/m/Y',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR Source -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.source')"
            >
              <v-select
                v-model="source"
                class="w-100"
                style="font-size: 1rem;"
                :options="distributorsList"
                label="label"
                clearable
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                :disabled="Boolean(fixFlightType)"
                @input="val => $emit('update:flightTypeFilter', val)"
              >
                <template #option="data">
                  <span>
                    {{ data.label }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ data.label }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR SaleReportTicketType -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.ticketType')"
            >
              <v-select
                v-model="saleReportTicketType"
                class="w-100"
                style="font-size: 1rem;"
                :options="saleReportTicketTypeOptions"
                label="label"
                clearable
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                @input="val => $emit('update:saleReportTicketType', val)"
              >
                <template #option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Airline -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0"
              :label="$t('saleReport.columns.airline')"
            >
              <b-form-input
                v-model.trim="airline"
                :placeholder="$t('saleReport.phAirline')"
                :formatter="trimUpperCaseInput"
                maxlength="2"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="auto"
            class="d-flex-center justify-content-end flex-1 gap-2"
          >
            <b-button
              variant="warning"
              size="sm"
              @click="onUpdateFilters"
            >
              <HStack
                center
                class="text-nowrap"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="SearchIcon"
                  size="16"
                />
                {{ $t('search') }}
              </HStack>
            </b-button>

            <b-button
              variant="danger"
              size="sm"
              @click="onClearFilters"
            >
              <HStack
                center
                class="text-nowrap"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="XOctagonIcon"
                  size="16"
                />
                {{ $t('clear') }}
              </HStack>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </AppCollapseItem>
  </AppCollapse>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import {
  flightTypeOptions,
  attachmentStatusOptions,
} from '@/constants/saleReport'
import { distributorsList, saleReportTicketTypeOptions } from '@/constants/selectOptions'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { trimUpperCaseInput } from '@core/comp-functions/forms/formatter-input'
import { convertISODateTime } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    SelectChildAgency: () => import('@/views/apps/retail/components/SelectChildAgency.vue'),
  },
  props: {
    searchText: {
      type: String,
      default: '',
    },
    fixFlightType: {
      type: String,
      default: '',
    },
  },

  watch: {
    fixFlightType: {
      handler(val) {
        this.flightType = val
      },
      immediate: true,
    },
  },

  setup(props, { emit }) {
    const startDate = ref(convertISODateTime(new Date()).dateFilter)
    const endDate = ref(convertISODateTime(new Date()).dateFilter)
    const agencySelect = ref(null)
    const flightType = ref('')
    const attachmentStatus = ref()
    const bookingCode = ref('')
    const flightDate = ref('')
    const source = ref('')
    const airline = ref('')
    const saleReportTicketType = ref('')

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const maxStartDate = computed(() => (endDate.value ? endDate.value : today))
    const minEndDate = computed(() => (startDate.value ? startDate.value : undefined))

    const onClearFilters = () => {
      startDate.value = convertISODateTime(new Date()).dateFilter
      endDate.value = convertISODateTime(new Date()).dateFilter
      agencySelect.value = null
      flightType.value = ''
      attachmentStatus.value = undefined
      bookingCode.value = ''
      source.value = ''
      airline.value = ''
      flightDate.value = ''
      saleReportTicketType.value = ''
      emit('refetch-data-without-filters')
    }

    const onUpdateFilters = () => {
      const filtersData = {
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
        agencyCode: agencySelect.value?.agencyCode || undefined,
        flightType: flightType.value || undefined,
        bookingCode: bookingCode.value || undefined,
        flightDate: flightDate.value || undefined,
        source: source.value || undefined,
        airline: airline.value || undefined,
        saleReportTicketType: saleReportTicketType.value || undefined,
      }
      emit('refetch-data-with-filters', filtersData)
    }

    return {
      // select Options
      flightTypeOptions,
      attachmentStatusOptions,

      startDate,
      endDate,
      agencySelect,
      flightType,
      attachmentStatus,
      bookingCode,
      flightDate,
      source,
      saleReportTicketType,
      airline,
      Vietnamese,

      today,
      maxStartDate,
      minEndDate,
      distributorsList,
      onClearFilters,
      onUpdateFilters,
      trimUpperCaseInput,

      saleReportTicketTypeOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}
</style>
